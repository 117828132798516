<template>
	<div class="page" style="max-width: 600px; margin: 0 auto;">
		<div style="background-color: #8dcffc;width:100%;height:100%;">


			<div style="position: relative;">
				<img src="../../../public/img/wj02bg.jpg" style="width: 100%; vertical-align: middle;" />
				<div style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1;">
					<div style="height: 25vw;">

					</div>
					<div style="position: relative; width: 80%; margin: 0 auto; ">
						<div :class="['stitem', ckeckedXX == item.xxid ? 'stitemckd' : '']" @click="ckeckedXX = item.xxid"
							v-for="(item, index) in tm0List" :key="index" style="">
							<div
								style=" line-height: 30px; color: #FFFFFF; font-size: 14px; font-weight: bold; text-align: center;">
								{{ item.title }}
							</div>
							<div style="width: 40px;height: 40px; margin: 0 auto; border-radius: 50%; overflow: hidden;">
								<img :src="'./img/wjst' + index + '.png'" style="width: 100%; " />
							</div>
							<div
								style="width: 20px; height: 20px;margin: 0 auto; margin-bottom: 5px; margin-top: 5px; border: 1px solid #fff; border-radius: 50%; overflow: hidden;">
								<img v-if="ckeckedXX == item.xxid" :src="'./img/check.png'"
									style="width: 100%; transform: translateY(-0px); " />
							</div>

						</div>



					</div>
					<div id="btnjoined" style=" display: none; text-align: center;font-size:20px;color:orangered;line-height: 100px;">您已参与过，请勿重复参与</div>
					<div id="btngo" @click="goNext(2)" style=" width: 50%; margin: 0 auto; margin-top: 30px;">
						<img src="../../../public/img/wj02_btn.png" style="width: 100%; vertical-align: middle; " />
					</div>

					

				</div>
			</div>

		</div>


		<div v-if="planInfo && false">
			<div style="padding: 20px;">
				<div class="bolder">
					{{ planInfo.plan_name }}
				</div>
				<div class="wjdesc">
					{{ planInfo.sta_time }} {{ planInfo.end_time }}
				</div>
			</div>

			<div style="">
				<div class="wjitem flexBetween" @click="goIvst(item)" v-for="(item, idx) in tempList">
					<div class="wjtitle">{{ item.ivst_title }}</div>

					<div class="wjdesc flexBetween">
						<!--						2021-01-01 至 2022-01-01-->
						<span class="wjstatus" v-if="item.joined" style="color: #009900;">已参与</span>
						<span class="wjstatus" style="color: orangered;" v-else>未参与</span>

						<div class="righticon">
							<img :src="'./img/right.png'">
						</div>
					</div>

				</div>

			</div>
		</div>



		<div v-if="showUser" style="position: absolute; width: 100%; height: 100%; top: 0; background: rgba(0,0,0,0.5);">
			<div style="height: 10vw;">

			</div>
			<div style="width: 70%; margin: 0 auto;">
				<img src="../../../public/img/wj_wsxx2.png" style="width: 100%; vertical-align: middle;" />
			</div>
			<div
				style="width: 70%; margin: 0 auto; background-color: #fff; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">姓名： <i style="color: orangered;">*</i> </span>
					<input type="text" v-model="userMore.stu_name"
						style="width: 60%; outline: none; border: 0; text-align: right; float: right;"
						placeholder="仅作为自动匹配班级" @input="checkUsername" @blur="checkUsernameok" />
				</div>
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">毕业时间： </span>
					<div style="float: right; position: relative; width: 50%; text-align: right;">

						<div>
							<input type="text" :value="userMore.gyear"
								style="width: 100%; outline: none; border: 0; text-align: right; float: right;"
								placeholder="请选择毕业年份" />
						</div>
						<div style="position: absolute; top: 0; width: 100%; height: 100%;">
							<select v-model="userMore.gyear" style="opacity: 0; width: 100%;" @change="changeYear">
								<option v-for="(y, i) in YearList" :key="i" :value="y.gyear">{{ y.gyear }}年</option>

							</select>
						</div>
					</div>
				</div>
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">班级： </span>
					<div style="float: right; position: relative; width: 50%; text-align: right;">

						<div>
							<input type="text" :value="userMore.class_name"
								style="width: 100%; outline: none; border: 0; text-align: right; float: right;"
								placeholder="请选择班级" />
						</div>
						<div style="position: absolute; top: 0; width: 100%; height: 100%;">
							<select v-model="userMore.class_name" style="opacity: 0; width: 100%;">
								<option v-for="(y, i) in ClassList" :key="i" :value="y.class_name">{{ y.class_name }}
								</option>

							</select>
						</div>
					</div>
				</div>
				<div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
					<span style="font-size: 14px;">手机号： </span>
					<input type="number" v-model="userMore.phone"
						style="width: 50%; outline: none; border: 0; text-align: right; float: right;"
						placeholder="请输入11位手机号" />
				</div>
				<div style="margin: 30px auto 0px auto; width: 60%; padding-bottom: 10px;" @click="confirmChoujiang">
					<img src="../../../public/img/btn_usertjbj.png" style="width: 100%;" />
				</div>
				<!-- <div style='text-align:center;font-size: 12px; padding-bottom: 10px;' @click="goNext(1)">跳过</div> -->
			</div>



		</div>




	</div>
</template>

<script>
export default {
	data() {
		return {
			plan: {
				pmid: 0,
				plan_id: 0,
				uid: 0,
				utype: "",
				ftype: ""
			},
			ivst_id: 0,
			planInfo: null,
			IvstInfo: null,
			tempList: [],
			color: ["1fa4fb", "3e6bff", "139efc", "149efc", "38aaf5", "2ea7f9", "3f6cfe", "109ffe", "4375ff"],
			tm0List: [],
			ckeckedXX: 0,
			step: 0,
			joined: false,
			showUser: false,
			userMore: {
				class_name: "",
				phone: "",
				gyear: "",
				stu_name: ""
			},
			YearList: [],
			ClassList: [],
		}
	},
	mounted() {

		if (this.$route.query.pid && this.$route.query.id) {

			let plan = {
				plan_id: this.$route.query.pid,
				id: this.$route.query.id,
				pmid: this.$route.query.pmid

			}
			this.plan = plan

			this.getIvst()
		} else {
			this.$message.error("参数错误")
		}
	},
	methods: {
		getIvst() {
			this.$http.post("/api/h5_ivst_one", {
				id: this.plan.id,
				pmid: this.plan.pmid,
				plan_id: this.plan.plan_id,
				   uid: sessionStorage.getItem("ivst_stu_id"),
				utype: 0,
			}).then(res => {
				document.title = res.data.ivst_title
				if (res.data.answers && res.data.answers.length > 0) {
					//sessionStorage.setItem("firsttm", this.IvstInfo.tm_list[0].id + ";" + this.ckeckedXX)
					//this.$router.push("/h5_ivst?pid=" + this.plan.plan_id + "&id=" + this.plan.id)
					this.$nextTick(e=>{
						$("#btnjoined").show()
						$("#btngo").hide()
					})
				} else {
					this.IvstInfo = res.data
					this.tm0List = JSON.parse(res.data.tm_list[0].tm_content)
				}

			})
		},
		confirmChoujiang() {
			if (!this.userMore.stu_name) {
				this.showToast("请输入姓名")
				return
			}
			if (!this.userMore.gyear) {
				this.showToast("请选择毕业时间")
				return
			}
			if (!this.userMore.class_name) {
				this.showToast("请选择班级")
				return
			}
			//检查用户是否绑定

			this.userMore.plan_id = this.plan.plan_id
			this.$http.post("/api/h5_ivst_check_join", this.userMore).then(res => {
				if (res.data.code == 200) {
					this.showUser = false;
					this.step = 1
					console.log(this.step)
				} else {
					this.showToast(res.data.msg)
				}

			})


		},
		checkUsername() {
			this.$http.post("/api/h5_stuname_check", { stu_name: this.userMore.stu_name }).then(res => {
				console.log(res.data)
				if (res.data.msg) {
					this.YearList = []
					this.userMore.gyear = "";
					this.userMore.class_name = "";
					this.ClassList = []
				} else {
					this.YearList = res.data

					if (this.YearList.length == 1) {
						this.userMore.gyear = this.YearList[0].gyear
						this.ClassList = this.YearList[0].classes
						if (this.ClassList.length == 1) {
							this.userMore.class_name = this.ClassList[0].class_name
						} else {
							this.userMore.class_name = "";
						}
					} else {
						this.userMore.gyear = "";
					}
				}
			})
		},
		showToast(msg) {
			$("#toast").text(msg).show().animate({
				opacity: 1
			}, 200, "linear", function () {
				setTimeout(() => {
					$("#toast").animate({
						opacity: 0
					}, "linear", 3000, function () {
						$("#toast").hide()
					})
				}, 2000)
			})
		},
		checkUsernameok() {
			this.checkUsername()
			setTimeout(() => {
				if (this.YearList.length == 0) {
					this.showToast("未找到该姓名的学生")
				}
			}, 1500);
			if (this.YearList.length == 0) {

				this.$http.post("/api/njwx_nomath_stus", { stu_name: this.userMore.stu_name, openid: sessionStorage.getItem("openid") })
			}


		},

		changeYear() {
			this.userMore.class_name = "";
			for (let item of this.YearList) {
				if (item.gyear == this.userMore.gyear) {
					this.ClassList = item.classes
					if (this.ClassList.length == 1) {
						this.userMore.class_name = this.ClassList[0].class_name
					}
				}
			}
			//this.getClass()

		},
		getClass() {
			this.$http.post("/api/h5_stu_classes", {
				year: this.userMore.gyear
			}).then(res => {
				this.ClassList = res.data
			})
		},
		goNext() {
			if (this.ckeckedXX) {
				console.log(this.tm0List)
				sessionStorage.setItem("firsttm", this.IvstInfo.tm_list[0].id + ";" + this.ckeckedXX)
				this.$router.push("/h5_ivst?pid=" + this.plan.plan_id + "&id=" + this.plan.id+"&pmid="+this.plan.pmid)
			}
		}


	},
}
</script>

<style>
.stitem {
	width: 45%;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	border: 2px solid #1fa4fb;
	background-color: #1fa4fb;
}

.stitemckd {
	border: 2px solid #f9b379;
}

.stitem:nth-child(2n+1) {
	margin-right: 10%;
}

.bolder {
	font-weight: bolder;
}

.wjitem {
	border-bottom: 1px solid #eee;
	font-size: 14px;
	padding: 10px 15px;
	position: relative;
}

.wjdesc {
	font-size: 12px;
	padding: 10px 0;
}

.wjstatus {
	/*float: right;*/
	margin-right: 10px;
	vertical-align: middle;
}

.wjtitle {
	margin-right: 30px;
}

.wjitem:active {
	background-color: #eee;
}

.righticon {
	width: 20px;
	height: 20px;
	/*position: absolute;*/
	/*top: 25px;*/
	/*right: 10px;*/
}

.righticon img {
	width: 100%;
	height: 100%;
}

.wjitem:nth-child(1) {
	border-top: 1px solid #eee;
}

.toast {
	position: fixed;
	width: 70%;
	left: 15%;
	text-align: center;
	padding: 5px 0;
	border-radius: 4px;
	bottom: 50%;
	background: orangered;
	color: #FFFFFF;
	font-size: 14px;
	opacity: 0;
	display: none;
	z-index: 99999;
}

.alert {
	position: absolute;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.6);
	opacity: 0;
}

.alertbox {
	width: 80%;
	background: #FFFFFF;
	border-radius: 4px;
	padding: 10px;
	margin: 0 auto;
}

.alerttitle {
	font-size: 16px;
	text-align: center;
	border-bottom: 1px solid #f6f6f6;
	padding-bottom: 10px;
}

.alertcontent {
	padding: 20px 0;
	word-break: break-all;
	text-align: center;
}

.alertbutton {
	border-top: 1px solid #f6f6f6;
	padding: 10px;
	text-align: center;
	background-color: #007AFF;
	color: #fff;
	border-radius: 6px;
	cursor: pointer;

}
</style>
